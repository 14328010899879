import React from 'react'
import PropTypes from 'prop-types'
import { usePageTransition } from '../../hooks/usePageTransition'
import { useContext } from 'react'
import { ContextDispatch, ContextState } from '../../context/Context'
import { PageTransitionLinkMain } from './index.style'

const PageTransitionLink = ({ children, to, fill, onClick, ...props }) => {
  const pageTransition = usePageTransition()
  const store = useContext(ContextState)
  const storeDispatch = useContext(ContextDispatch)

  return (
    <PageTransitionLinkMain
      to={to}
      exit={pageTransition.exit}
      entry={pageTransition.entry}
      fill={fill ? 'true' : undefined}
      {...props}
      onClick={() => {
        if (
          to.indexOf(store.pathname) === -1 ||
          to.length !== store.pathname.length
        ) {
          if (onClick) {
            onClick()
          }
          storeDispatch({ type: 'HIDE_MOBILE_NAV' })
          storeDispatch({ type: 'UPDATE_PATHNAME', payload: to })
          storeDispatch({ type: 'SHOW_TRANSITION_MASK' })
          storeDispatch({ type: 'CLOSE_CURSOR_LINK' })
        }
      }}
    >
      {children}
    </PageTransitionLinkMain>
  )
}

PageTransitionLink.propTypes = {
  children: PropTypes.node,
  fill: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
}

PageTransitionLink.defaultProps = {
  fill: false,
}

export default PageTransitionLink
