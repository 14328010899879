import React, { useRef, useEffect, useContext } from 'react'
import gsap from 'gsap'
import { ContextDispatch, ContextState } from '../../context/Context'
import { TransitionMaskWrapper, TransitionMaskBlock } from './index.style'
import { animation } from '../../styles/vars/animation.style'

const TransitionMask = () => {
  const store = useContext(ContextState)
  const dispatch = useContext(ContextDispatch)
  const maskWrapperRef = useRef()
  const maskRef = useRef()

  useEffect(() => {
    const mask = maskRef.current
    let showTween = null
    let hideTween = null

    const killShowTween = () => {
      showTween.kill()
      showTween = null
    }

    const killHideTween = () => {
      hideTween.kill()
      hideTween = null
    }

    if (store.showTransitionMask) {
      gsap.killTweensOf(mask)

      showTween = gsap.to(mask, {
        opacity: 1,
        duration: animation.pageExitDuration,
        ease: 'power2.inOut',
        onStart: () => {
          dispatch({ type: 'HIDE_HEADER' })
          dispatch({ type: 'CLOSE_CURSOR' })
          dispatch({ type: 'SET_SERVICES_IN_VIEW', payload: -1 })
          dispatch({ type: 'SET_ACTIVE_BLOCK_INDEX', payload: 0 })
        },
        onComplete: () => {
          killShowTween()
        },
      })
    }

    if (!store.showTransitionMask) {
      gsap.killTweensOf(mask)

      hideTween = gsap.to(mask, {
        opacity: 0,
        duration: animation.pageEntryDuration,
        ease: 'power2.inOut',
        delay: 1,
        onComplete: () => {
          dispatch({ type: 'SHOW_HEADER' })
          killHideTween()
        },
      })
    }

    return () => {
      if (showTween) {
        killShowTween()
      }

      if (hideTween) {
        killHideTween()
      }
    }
  }, [store.showTransitionMask, dispatch])

  return (
    <TransitionMaskWrapper ref={maskWrapperRef}>
      <TransitionMaskBlock id="transitionMaskBlock" ref={maskRef} />
    </TransitionMaskWrapper>
  )
}

export default TransitionMask
