import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { CharacterMaskBlock, CharacterMaskMain } from './index.style'

gsap.registerPlugin(SplitText)

const CharacterMask = ({ children }) => {
  const $textA = useRef()
  const $textB = useRef()
  const $textASplit = useRef()
  const $textBSplit = useRef()
  const isAnimating = useRef(false)
  const tl = useRef()
  const charClassName = 'char'

  const setup = (ref, splitRef) => {
    const type = 'chars'

    splitRef.current = new SplitText(ref.current, { type })

    splitRef.current[type].forEach(item => {
      item.classList.add(charClassName)
      item.setAttribute('aria-hidden', true)
    })
  }

  const animate = useCallback(() => {
    if (isAnimating.current) return

    const selectA = gsap.utils.selector($textA)
    const selectB = gsap.utils.selector($textB)
    const selectAChars = selectA(`.${charClassName}`)
    const selectBChars = selectB(`.${charClassName}`)
    const staggerVal = 0.045
    const delayVal = staggerVal * 3

    isAnimating.current = true

    tl.current = gsap.timeline()

    tl.current
      .fromTo(
        selectAChars,
        { yPercent: 0 },
        {
          yPercent: -100,
          stagger: staggerVal,
          ease: 'power2.out',
          duration: 0.2,
          immediateRender: true,
        }
      )
      .fromTo(
        selectBChars,
        { yPercent: 0 },
        {
          yPercent: -100,
          stagger: staggerVal,
          ease: 'power2.out',
          duration: 0.2,
          immediateRender: true,
          onComplete: () => {
            gsap.set([selectAChars, selectBChars], {
              yPercent: 0,
            })
            isAnimating.current = false
          },
        },
        delayVal
      )
  }, [])

  useEffect(() => {
    setup($textA, $textASplit)
    setup($textB, $textBSplit)

    return () => {
      tl.current?.kill()
    }
  }, [])

  return (
    <CharacterMaskMain onMouseEnter={animate}>
      <CharacterMaskBlock ref={$textA} aria-label={children}>
        {children}
      </CharacterMaskBlock>

      <CharacterMaskBlock ref={$textB} aria-hidden>
        {children}
      </CharacterMaskBlock>
    </CharacterMaskMain>
  )
}

CharacterMask.propTypes = {
  children: PropTypes.node,
}

export default CharacterMask
