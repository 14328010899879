import gsap from 'gsap'

export function disablePageScroll() {
  document.body.classList.add('disable-scroll')
}

export function enablePageScroll() {
  document.body.classList.remove('disable-scroll')
}

export function scrollTo(id, dispatch) {
  const targetEl = document.getElementById(id)
  const webglEl = document.getElementById('webgl-wrapper')

  gsap.to(webglEl, {
    opacity: 1,
    duration: 0.1,
    ease: 'power2.in',
    onComplete: () => {
      if (dispatch) {
        dispatch({ type: 'START_SCROLL_TO' })
      }
    },
  })

  window.scroll.scrollTo(id === 'careers' ? 'bottom' : targetEl, {
    offset: -240,
    duration: 3000,
    callback: () => {
      window.scroll.update()

      gsap.to(webglEl, {
        opacity: 0,
        duration: 0.3,
        delay: 0.25,
        ease: 'power2.out',
        onComplete: () => {
          if (dispatch) {
            dispatch({ type: 'END_SCROLL_TO' })
          }
        },
      })
    },
  })
}
