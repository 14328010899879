import styled, { keyframes } from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'

const duration = 0.35
const staggerDelay = 0.35
const preFadeOutDelay = 0.4
const fadeOutDuration = 0.2
const postFadeOutDelay = 1.5

const getTotalDuration = groupsTotal =>
  groupsTotal * duration + preFadeOutDelay + fadeOutDuration + postFadeOutDelay
const getPostFadePercentage = totalDuration =>
  (postFadeOutDelay / totalDuration) * 100

const fadeOut = groupsTotal => {
  const totalDuration = getTotalDuration(groupsTotal)
  const postFadePercentage = getPostFadePercentage(totalDuration)
  const durationPercentage = (fadeOutDuration / totalDuration) * 100
  const startFadeOut = 100 - durationPercentage - postFadePercentage
  const endFadeOut = 100 - postFadePercentage

  return keyframes`
  0%,
  ${startFadeOut}% {
    opacity: 1;
  }

  ${endFadeOut}%,
  100% {
    opacity: 0;
  }
`
}

const slideIn = (y, groupsTotal, index) => {
  const totalDuration = getTotalDuration(groupsTotal)
  const startSlideIn = ((staggerDelay * index) / totalDuration) * 100
  const durationPercentage = (duration / totalDuration) * 100
  const endSlideIn = startSlideIn + durationPercentage
  const endFadeOut = 100 - getPostFadePercentage(totalDuration)

  return keyframes`
    0%,
    ${startSlideIn}% {
      transform : translateY(-${y / 10}rem);
    }

    ${endSlideIn}% {
        transform : translateY(0);
    }

    ${endFadeOut + 1}% {
      transform : translateY(0);
    }

    ${endFadeOut + 2}%,
    100% {
      transform : translateY(-${y / 10}rem);
    }
`
}

export const TetrisSVG = styled.svg`
  ${({ inline }) => {
    if (inline) {
      return `
        display: inline-flex;
        ${clamp('margin-left', 10, 20)}
        margin-bottom: 0.1rem;
      `
    }
  }}
  animation: ${props => fadeOut(props.groupsTotal)} ${props =>
    `${getTotalDuration(
      props.groupsTotal
    )}s`} cubic-bezier(0.46, 0.03, 0.52, 0.96) ${props => props.delay}s infinite
`

export const TetrisGroup = styled.g`
  transform: ${props => `translateY(-${props.blockHeight / 10}rem)`};
  animation: ${props =>
      slideIn(props.blockHeight, props.groupsTotal, props.index)}
    ${props => `${getTotalDuration(props.groupsTotal)}s`}
    cubic-bezier(0.46, 0.03, 0.52, 0.96) ${props => props.delay}s infinite;
`
