import React, { useEffect, useRef, useContext } from 'react'
import gsap from 'gsap'
import { ContextState, ContextDispatch } from '../../context/Context'
import { disablePageScroll, enablePageScroll } from '../../utils/utils'
import { MenuToggleMain, MenuToggleBar, MenuToggleWrapper } from './index.style'

const MenuToggle = () => {
  const store = useContext(ContextState)
  const { mobileNavIsOpen, showHeader } = store
  const dispatch = useContext(ContextDispatch)
  const toggleRef = useRef()
  const barRefA = useRef()
  const barRefB = useRef()

  useEffect(() => {
    const tlOpen = gsap.timeline({ paused: true })
    const tlClose = gsap.timeline({ paused: true })

    tlOpen
      .to(barRefA.current, {
        transformOrigin: 'center',
        y: 5,
        ease: 'power3.inOut',
        duration: 0.1,
      })
      .to(
        barRefA.current,
        {
          rotate: 75,
          ease: 'power3.inOut',
          duration: 0.2,
        },
        0.1
      )
      .to(
        barRefB.current,
        {
          transformOrigin: 'center',
          y: -5,
          ease: 'power3.inOut',
          duration: 0.1,
        },
        0
      )
      .to(
        barRefB.current,
        {
          rotate: -15,
          ease: 'power3.inOut',
          duration: 0.2,
        },
        0.1
      )

    tlClose
      .to(barRefA.current, {
        transformOrigin: 'center',
        y: 0,
        rotate: 0,
        ease: 'power3.inOut',
        duration: 0.2,
      })
      .to(
        barRefB.current,
        {
          transformOrigin: 'center',
          y: 0,
          rotate: 0,
          ease: 'power3.inOut',
          duration: 0.2,
        },
        0
      )

    if (mobileNavIsOpen) {
      disablePageScroll()
      tlOpen.play()
    } else {
      enablePageScroll()
      tlClose.play()
    }

    return () => {
      tlOpen.kill()
      tlClose.kill()
    }
  }, [mobileNavIsOpen])

  useEffect(() => {
    gsap.to(toggleRef.current, {
      yPercent: showHeader ? 0 : -100,
      duration: 0.6,
      delay: 0.3,
      ease: 'inOutDefault',
    })
  }, [showHeader])

  const handleClick = () => {
    if (mobileNavIsOpen) {
      dispatch({ type: 'HIDE_MOBILE_NAV' })
    } else {
      dispatch({ type: 'SHOW_MOBILE_NAV' })
    }
  }

  return (
    <MenuToggleWrapper ref={toggleRef}>
      <MenuToggleMain onClick={handleClick} aria-label="Toggle menu">
        <MenuToggleBar ref={barRefA} />
        <MenuToggleBar ref={barRefB} />
      </MenuToggleMain>
    </MenuToggleWrapper>
  )
}

export default MenuToggle
