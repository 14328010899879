import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ScrollContainerMain } from './index.style'
import { ContextDispatch } from '../../context/Context'

const ScrollContainer = ({ children }) => {
  const dispatch = useContext(ContextDispatch)

  return (
    <ScrollContainerMain
      id="scroll-container"
      data-scroll-container
      onMouseEnter={() => dispatch({ type: 'SHOW_CURSOR' })}
      onMouseLeave={() => dispatch({ type: 'HIDE_CURSOR' })}
    >
      {children}
    </ScrollContainerMain>
  )
}

ScrollContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ScrollContainer
