import styled from 'styled-components'
import { easings } from '../../../styles/vars/easings.style'

export const AnimateSlideInMask = styled.span`
  display: block;
  overflow: hidden;
`

const BaseAnimateSlideInContent = styled.span`
  display: block;
  transition-property: transform;
  transition-timing-function: ${easings.inOut.default};

  [data-inview='true'] & {
    transform: translate(0, 0);
  }
`

export const AnimateSlideInContent = styled(BaseAnimateSlideInContent).attrs(
  props => {
    return {
      style: {
        transitionDuration: `${props.duration}s`,
        transitionDelay: `${props.delay}s`,
      },
    }
  }
)`
  transform: ${({ direction }) => {
    let offset

    switch (direction) {
      case 'top':
        offset = '0, -102%'
        break

      case 'right':
        offset = '102%, 0%'
        break

      case 'bottom':
        offset = '0%, 102%'
        break

      case 'left':
        offset = '-102%, 0%'
        break

      default:
        break
    }

    return `translate(${offset})`
  }};
`
