import React from 'react'
import PropTypes from 'prop-types'
import { TetrisGroup, TetrisSVG } from './index.style'

const paths = [
  ['M9 18h2v2H9z', 'M9 12h2v2H9z', 'M9 15h2v2H9z', 'M9 9h2v2H9z'],
  ['M3 18h2v2H3z', 'M3 15h2v2H3z', 'M6 18h2v2H6z', 'M6 15h2v2H6z'],
  ['M0 18h2v2H0z', 'M0 12h2v2H0z', 'M0 15h2v2H0z', 'M3 12h2v2H3z'],
  ['M18 18h2v2h-2z', 'M18 12h2v2h-2z', 'M18 15h2v2h-2z', 'M18 9h2v2h-2z'],
  ['M3 9h2v2H3z', 'M6 12h2v2H6z', 'M6 6h2v2H6z', 'M6 9h2v2H6z'],
  ['M0 6h2v2H0z', 'M0 9h2v2H0z', 'M3 3h2v2H3z', 'M3 6h2v2H3z'],
  ['M6 3h2v2H6z', 'M9 3h2v2H9z', 'M9 6h2v2H9z', 'M9 0h2v2H9z'],
  ['M0 3h2v2H0z', 'M0 0h2v2H0z', 'M3 0h2v2H3z', 'M6 0h2v2H6z'],
  ['M12 18h2v2h-2z', 'M12 15h2v2h-2z', 'M15 18h2v2h-2z', 'M15 15h2v2h-2z'],
  ['M12 12h2v2h-2z', 'M12 3h2v2h-2z', 'M12 6h2v2h-2z', 'M12 9h2v2h-2z'],
  ['M15 12h2v2h-2z', 'M15 6h2v2h-2z', 'M15 9h2v2h-2z', 'M18 6h2v2h-2z'],
  ['M12 0h2v2h-2z', 'M15 3h2v2h-2z', 'M15 0h2v2h-2z'],
  ['M18 3h2v2h-2z', 'M18 0h2v2h-2z'],
]

const Tetris = ({
  width = 20,
  height = 20,
  fill = 'currentColor',
  responsive = false,
  inline = false,
  delay = 1,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <TetrisSVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      inline={inline}
      groupsTotal={paths.length}
      delay={delay}
      {...svgAttributes}
    >
      {React.Children.toArray(
        paths.map((pathGroup, pathGroupIndex) => (
          <TetrisGroup
            fill={fill}
            groupsTotal={paths.length}
            index={pathGroupIndex}
            blockHeight={height}
            delay={delay}
          >
            {React.Children.toArray(pathGroup.map(path => <path d={path} />))}
          </TetrisGroup>
        ))
      )}
    </TetrisSVG>
  )
}

Tetris.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  delay: PropTypes.number,
  fill: PropTypes.string,
  responsive: PropTypes.bool,
  inline: PropTypes.bool,
}

export default Tetris
