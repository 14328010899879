import styled from 'styled-components'
import { easings } from '../../styles/vars/easings.style'
import { colors } from '../../styles/vars/colors.style'

export const WebGLCanvas = styled.canvas`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s ${easings.out.cubic};
`

export const WebGLOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: ${colors.dark};
`
