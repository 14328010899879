import styled from 'styled-components'

export const CharacterMaskMain = styled.div`
  position: relative;
  overflow: hidden;
`

export const CharacterMaskBlock = styled.div`
  :nth-child(2) {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
  }
`
