import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { zIndex } from '../../styles/vars/layout.style'

export const CursorWrapper = styled.div.attrs(({ x, y, show }) => ({
  style: {
    transform: `translate(${x}px, ${y}px)`,
    opacity: show ? 1 : 0,
  },
}))`
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: ${zIndex.cursor};
  transition: opacity 0.15s ease;
  will-change: transform, opacity;
`

export const CursorMain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ open }) =>
    open ? 'translate(-50%, -50%) scale(0)' : 'translate(-50%, -50%) scale(1)'};
  width: 1rem;
  height: 1rem;
  border-width: 0.1rem;
  border-style: solid;
  border-color: ${({ light }) => (light ? colors.light : colors.dark)};
  border-radius: 50%;
  transition: ${({ open }) =>
    open
      ? `transform 0.2s ${easings.inOut.cubic}, border-color 0.15s ${easings.inOut.cubic}`
      : `transform 0.2s ${easings.inOut.cubic} 0.2s, border-color 0.15s ${easings.inOut.cubic}`};
  will-change: transform;
`

export const CursorLink = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ open }) =>
    open ? 'translate(-50%, -50%) scale(1)' : 'translate(-50%, -50%) scale(0)'};
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${({ light }) => (light ? colors.light : colors.dark)};
  transition: transform 0.2s ${easings.inOut.cubic};
  will-change: transform;
`

export const CursorCircle = styled.div.attrs(({ open }) => ({
  style: {
    width: open ? '11rem' : 0,
    height: open ? '11rem' : 0,
    backgroundColor: open ? colors.light : 'transparent',
  },
}))`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  color: ${colors.dark};
  border-radius: 50%;
  will-change: width, height;
  transition-property: width, height, background-color;
  transition-duration: 0.4s;
  transition-timing-function: ${easings.inOut.cubic};
`

export const CursorTextMask = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
`

export const CursorText = styled.div.attrs(({ open }) => ({
  style: {
    transform: `translateY(${open ? '0%' : '100%'})`,
    transitionDelay: open ? '0.25s' : '0s',
  },
}))`
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.dark};
  white-space: nowrap;
  transition: transform 0.3s ease;
  will-change: transform;
`

export const CursorArrow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 0.7rem;
  height: 0.7rem;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: ${({ open }) => (open ? 'opacity 0.1s' : 'opacity 0.1s 0.2s')};
  will-change: opacity;

  :before,
  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform-origin: 100% 0.2rem;
    background-color: ${colors.light};
    transition: transform 0.2s ease;
    will-change: transform;
  }

  :before {
    width: 100%;
    height: 0.2rem;
    transform: ${({ open }) => `rotate(${open ? '0' : '-45'}deg)`};
  }

  :after {
    width: 0.2rem;
    height: 100%;
    transform: ${({ open }) => `rotate(${open ? '0' : '45'}deg)`};
  }
`

export const CursorDragArrow = styled.div`
  position: absolute;
  top: 50%;
  width: 0.7rem;
  height: 0.7rem;
  margin-top: -0.35rem;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: ${({ open }) => (open ? 'opacity 0.1s' : 'opacity 0.1s 0.2s')};
  will-change: opacity;

  :first-child {
    right: -2.1rem;
    transform: rotate(45deg);
  }

  :nth-child(2) {
    left: -2.1rem;
    transform: rotate(-135deg);
  }

  :before,
  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform-origin: 100% 0.2rem;
    background-color: ${colors.light};
    transition: transform 0.2s ease;
    will-change: transform;
  }

  :before {
    width: 100%;
    height: 0.2rem;
    transform: ${({ open }) => `rotate(${open ? '0' : '-45'}deg)`};
  }

  :after {
    width: 0.2rem;
    height: 100%;
    transform: ${({ open }) => `rotate(${open ? '0' : '45'}deg)`};
  }
`
