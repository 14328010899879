import { useRef, useContext, useEffect, useState, useCallback } from 'react'
// We are excluding this from loading at build time in gatsby-node.js
import gsap from 'gsap'
import { GSDevTools } from 'gsap/GSDevTools'
import LocomotiveScroll from 'locomotive-scroll'
import { ContextState, ContextDispatch } from '../../context/Context'
import { animation } from '../../styles/vars/animation.style'
import { useInterval } from '../../hooks/useInterval'

gsap.registerPlugin(GSDevTools)

const Scroll = ({ pathname, desktop }) => {
  const store = useContext(ContextState)
  const dispatch = useContext(ContextDispatch)
  const [intervalRunning, setIntervalRunning] = useState(false)
  const showHeader = useRef(store.showHeader)
  const showHeaderGradient = useRef(store.showHeaderGradient)
  const scrollToIsActive = useRef(store.scrollToIsActive)
  const scrollSpeed = useRef(0)
  const scrollSpeedThreshold = 15
  const animationIsHidden = useRef(false)
  const triggeredSections = useRef([])
  const previousScrollSpeed = useRef(null)

  const endFastScroll = () => {
    if (scrollToIsActive.current) return

    const $webgl = document.getElementById('webgl-wrapper')

    setIntervalRunning(false)

    gsap.killTweensOf(window.tl)
    window.tl.seek(triggeredSections.current[1])

    gsap.to($webgl, {
      opacity: 0,
      duration: 0.25,
      ease: 'power2.out',
      onComplete: () => {
        animationIsHidden.current = false
      },
    })
  }

  const startFastScroll = useCallback(() => {
    if (scrollToIsActive.current) return

    const $webgl = document.getElementById('webgl-wrapper')
    animationIsHidden.current = true

    gsap.to($webgl, {
      opacity: 1,
      duration: 0.25,
      ease: 'power2.in',
      onComplete: () => {
        setIntervalRunning(true)
        gsap.killTweensOf(window.tl)
      },
    })
  }, [])

  useInterval(
    () => {
      if (
        previousScrollSpeed.current &&
        previousScrollSpeed.current === scrollSpeed.current
      ) {
        scrollSpeed.current = 0
        previousScrollSpeed.current = null
      }

      if (scrollSpeed.current < 2 && animationIsHidden.current) {
        endFastScroll()
      }

      previousScrollSpeed.current = scrollSpeed.current
    },
    intervalRunning ? 500 : null
  )

  useEffect(() => {
    showHeader.current = store.showHeader
  }, [store.showHeader])

  useEffect(() => {
    showHeaderGradient.current = store.showHeaderGradient
  }, [store.showHeaderGradient])

  useEffect(() => {
    scrollToIsActive.current = store.scrollToIsActive
  }, [store.scrollToIsActive])

  useEffect(() => {
    const locomotiveScroll = new LocomotiveScroll({
      el: document.getElementById('scroll-container'),
      smooth: false,
      smartphone: {
        smooth: false,
      },
      direction: 'vertical',
      getSpeed: true,
      getDirection: true,
      touchMultiplier: 2.5,
      firefoxMultiplier: 70,
      lerp: 0.1,
      repeat: false,
      class: 'is-inview',
      reloadOnContextChange: true,
    })

    window.followMouse = true
    window.scroll = locomotiveScroll
    window.scroll.stop()

    const timeout = setTimeout(() => {
      const headerHideThreshold = 100
      const webglApi = window.webglApi
      let scrollDirection

      window.tl = gsap.timeline({
        id: 'webgl-timeline',
        paused: true,
        defaults: { ease: 'power1.inOut' },
      })

      window.scroll.update()
      window.scroll.start()

      const setTimeline = () => {
        window.tl
          .set(webglApi.properties, {
            frame: 0,
          })
          .addLabel('start')
          .to(webglApi.properties, {
            frame: 48,
            duration: 3,
          })
          .addLabel('toEntered')
          .to(webglApi.properties, {
            frame: 190,
            cameraOffsetY: 0,
            cameraZoom: desktop ? 0.4 : 0,
            duration: 5,
          })

          .fromTo(
            webglApi.properties,
            {
              bloomAmount: 3.455,
              bloomRadius: 0.543,
              bloomSmoothWidth: 0.9,
              bloomHaloWidth: 0.75,
              contrast: 0,
            },
            {
              bloomAmount: 30,
              bloomRadius: 0.8,
              bloomSmoothWidth: 0,
              bloomHaloWidth: 0,
              contrast: 0.5,
              ease: 'power2.in',
              duration: 1,
              immediateRender: false,
            },
            '<3'
          )
          .to(
            webglApi.properties,
            {
              bloomAmount: 3.455,
              bloomRadius: 0.543,
              bloomSmoothWidth: 0.9,
              bloomHaloWidth: 0.75,
              contrast: 0,
              duration: 0.25,
            },
            'toEntered+=4.1'
          )

          .addLabel('toHiddenA')
          .to(webglApi.properties, {
            frame: 250,
            cameraOffsetY: 1.4,
            cameraZoom: 1,
            duration: 2,
          })
          .addLabel('toEnterSingle')
          .to(webglApi.properties, {
            frame: 512,
            cameraLookStrength: 0.26,
            cameraLookEaseDamp: 0.075,
            reflectOffsetX: 0.26,
            duration: 4,
          })
          .addLabel('spin')
          .to(webglApi.properties, {
            frame: 665,
            cameraShakePositionStrength: 0.3,
            cameraShakePositionSpeed: 0.3,
            cameraShakeRotationStrength: 0.003,
            cameraShakeRotationSpeed: 0.3,
            cameraLookStrength: 0.025,
            cameraLookEaseDamp: 0.05,
            cameraZoom: desktop ? 0.4 : 0,
            cameraOffsetX: 0,
            cameraOffsetY: 0,
            bloomRadius: 0.543,
            bloomSmoothWidth: 0.9,
            vignetteFrom: 0,
            vignetteTo: 1.4,
            reflectOffsetX: 0.4,
            duration: 1.5,
          })
          .addLabel('toHiddenB')
          .to(webglApi.properties, {
            frame: 892,
            cameraShakePositionStrength: 0.07475,
            cameraShakePositionSpeed: 0.00361,
            cameraShakeRotationStrength: 0.003,
            cameraShakeRotationSpeed: 0.12811,
            cameraLookStrength: 0.06619,
            cameraLookEaseDamp: 0.04227,
            cameraOffsetY: 0,
            bloomRadius: 0.72419,
            bloomSmoothWidth: 5,
            reflectOffsetX: 0.26,
            duration: 2.7,
          })
          .to(
            webglApi.properties,
            {
              vignetteFrom: 0,
              vignetteTo: 0,
              duration: 0.2,
            },
            '<2.2'
          )
          .set(webglApi.properties, {
            frame: 1003,
            cameraZoom: 5,
            cameraOffsetX: 1.3,
            vignetteFrom: 6,
            vignetteTo: 3,
          })
          .addLabel('fadedOut')
          .to(webglApi.properties, {
            vignetteFrom: 1.23802,
            vignetteTo: 0.49104,
            ease: 'power3.out',
            duration: 0.6,
          })
          .addLabel('toZoomedIn')
          .to(webglApi.properties, {
            vignetteFrom: 6,
            duration: 0.6,
            cameraZoom: desktop ? 0.4 : 0,
          })
          .set(webglApi.properties, {
            frame: 960,
            vignetteFrom: 0,
            vignetteTo: 6,
          })
          .to(webglApi.properties, {
            frame: 1170,
            cameraShakePositionStrength: 0.03,
            cameraShakePositionSpeed: 0.3,
            cameraShakeRotationStrength: 0.003,
            cameraShakeRotationSpeed: 0.3,
            cameraLookStrength: 0.26,
            cameraLookEaseDamp: 0.075,
            cameraOffsetX: 0,
            cameraOffsetY: 0,
            bloomRadius: 0.543,
            bloomSmoothWidth: 0.9,
            vignetteFrom: 0,
            vignetteTo: 1.4,
            reflectOffsetX: 0.1,
            duration: 4,
          })
          .addLabel('end')
          .to(webglApi.properties, {
            frame: 1200,
          })
      }
      setTimeline()

      window.scroll.on('scroll', args => {
        scrollSpeed.current = Math.abs(args.speed)

        if (
          scrollSpeed.current > scrollSpeedThreshold &&
          !animationIsHidden.current
        ) {
          startFastScroll()
        }

        if (scrollDirection !== args.direction) {
          scrollDirection = args.direction
        }

        // Toggle header gradient visibility

        if (args.scroll.y > headerHideThreshold) {
          if (!showHeaderGradient.current) {
            dispatch({ type: 'SHOW_HEADER_GRADIENT' })
          }
        } else {
          if (showHeaderGradient.current) {
            dispatch({ type: 'HIDE_HEADER_GRADIENT' })
          }
        }

        // Toggle header visibility
        if (
          args.direction === 'down' &&
          args.scroll.y > headerHideThreshold &&
          showHeader.current
        ) {
          dispatch({ type: 'HIDE_HEADER' })
        }

        if (args.direction === 'up' && !showHeader.current) {
          dispatch({ type: 'SHOW_HEADER' })
        }
      })

      function setTriggeredSections(tweenLabel) {
        if (triggeredSections.current.length >= 2) {
          triggeredSections.current.shift()
        }
        triggeredSections.current.push(tweenLabel)
      }

      function setTweenTo(name, duration) {
        gsap.killTweensOf(window.tl)

        const options = {}

        if (duration) {
          options.duration = duration
        }

        if (scrollToIsActive.current) {
          window.tl.seek(name)
        } else {
          window.tl.tweenTo(name, options)
        }

        setTriggeredSections(name)
      }

      window.scroll.on('call', (value, way) => {
        // Update the WebGL animation timeline

        if (scrollDirection === undefined) {
          if (value === 'hero') {
            if (way === 'enter') {
              setTweenTo('toEntered', 3)
            }
          }
        }

        if (scrollDirection === 'down') {
          if (value === 'hero') {
            if (way === 'exit') {
              setTweenTo('toHiddenA', 5)
            }
          }

          if (value === 'brand') {
            if (way === 'exit') {
              setTweenTo('toEnterSingle', 2)
            }
          }

          if (value === 'servicesA') {
            if (way === 'enter') {
              setTweenTo('spin', 4)
              dispatch({ type: 'SET_SERVICES_IN_VIEW', payload: 1 })
            }
          }

          if (value === 'process') {
            if (way === 'enter') {
              setTweenTo('toHiddenB', 1.5)
            }
          }

          if (value === 'team') {
            if (way === 'enter') {
              window.followMouse = false
              setTweenTo('toZoomedIn', 2)
            }
          }

          if (value === 'advisors') {
            if (way === 'exit') {
              window.followMouse = true
              setTweenTo('end', 4.6)
            }
          }
        }

        if (scrollDirection === 'up') {
          if (value === 'hero') {
            if (way === 'enter') {
              setTweenTo('toEntered', 2.3)
            }
          }

          if (value === 'brand') {
            if (way === 'enter') {
              setTweenTo('toHiddenA', 2.2)
            }
          }

          if (value === 'servicesA') {
            if (way === 'exit') {
              setTweenTo('toEnterSingle', 1)
            }
          }

          if (value === 'process') {
            if (way === 'exit') {
              setTweenTo('spin', 2.5)
            }
          }

          if (value === 'team') {
            if (way === 'exit') {
              setTweenTo('toHiddenB', 2)
              window.followMouse = true
            }
          }

          if (value === 'careers') {
            if (way === 'exit') {
              setTweenTo('toZoomedIn', 2)
              window.followMouse = false
            }
          }
        }

        // Update the active header navigation link

        if (value === 'strategy') {
          if (scrollDirection === 'down' && way === 'enter') {
            dispatch({ type: 'SET_ACTIVE_BLOCK_INDEX', payload: 1 })
          }

          if (scrollDirection === 'up' && way === 'exit') {
            dispatch({ type: 'SET_ACTIVE_BLOCK_INDEX', payload: 0 })
          }
        }

        if (value === 'process') {
          if (scrollDirection === 'down' && way === 'enter') {
            dispatch({ type: 'SET_ACTIVE_BLOCK_INDEX', payload: 2 })
          }

          if (scrollDirection === 'up' && way === 'exit') {
            dispatch({ type: 'SET_ACTIVE_BLOCK_INDEX', payload: 1 })
          }
        }

        if (value === 'team') {
          if (scrollDirection === 'down' && way === 'enter') {
            dispatch({ type: 'SET_ACTIVE_BLOCK_INDEX', payload: 3 })
          }

          if (scrollDirection === 'up' && way === 'exit') {
            dispatch({ type: 'SET_ACTIVE_BLOCK_INDEX', payload: 2 })
          }
        }

        if (value === 'careers') {
          if (scrollDirection === 'down' && way === 'enter') {
            dispatch({ type: 'SET_ACTIVE_BLOCK_INDEX', payload: 4 })
          }

          if (scrollDirection === 'up' && way === 'exit') {
            dispatch({ type: 'SET_ACTIVE_BLOCK_INDEX', payload: 3 })
          }
        }
      })
    }, (animation.siteLoadDelay + animation.pageEntryDuration) * 1000)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }

      if (window.tl) {
        window.tl.clear()
        gsap.killTweensOf(window.tl)
        window.tl.kill()
        window.tl = null
      }

      if (window.scroll) {
        window.scroll.destroy()
      }
    }
  }, [pathname, desktop, dispatch, startFastScroll])

  useEffect(() => {
    setIntervalRunning(false)
  }, [pathname])

  return null
}

export default Scroll
