import React from 'react'
import { grid } from '../../../styles/vars/grid.style'
import { GridMain } from './index.style'

const Grid = ({
  children,
  tiny,
  mobile,
  mobileL,
  tabletP,
  tabletL,
  desk,
  deskL,
  contained,
  colCount = grid.colCount,
  gutters = true,
}) => {
  return (
    <GridMain
      colCount={colCount}
      tiny={tiny}
      mobile={mobile}
      mobileL={mobileL}
      tabletP={tabletP}
      tabletL={tabletL}
      desk={desk}
      deskL={deskL}
      contained={contained}
      gutters={gutters}
    >
      {children}
    </GridMain>
  )
}

export default Grid
