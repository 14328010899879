import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  CursorDragArrow,
  CursorArrow,
  CursorCircle,
  CursorMain,
  CursorText,
  CursorTextMask,
  CursorWrapper,
  CursorLink,
} from './index.style'
import { ContextState } from '../../context/Context'

const Cursor = () => {
  const store = useContext(ContextState)
  const [mousePos, setMousePos] = useState([0, 0])
  const mousePosRef = useRef([0, 0])
  const show = store.cursor
  const open = store.cursorOpen
  const arrowType = store.cursorArrowType
  const light = store.cursorLight
  const linkIsOpen = store.cursorLink

  useEffect(() => {
    let raf

    const handleMouseMove = e => {
      mousePosRef.current = [e.clientX, e.clientY]
    }

    const renderLoop = () => {
      setMousePos([mousePosRef.current[0], mousePosRef.current[1]])
      raf = window.requestAnimationFrame(renderLoop)
    }

    document.addEventListener('mousemove', handleMouseMove)
    raf = window.requestAnimationFrame(renderLoop)

    return () => {
      window.cancelAnimationFrame(raf)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  return (
    <CursorWrapper x={mousePos[0]} y={mousePos[1]} show={show}>
      <CursorMain open={open} light={light} />

      <CursorLink open={linkIsOpen} light={light} />

      <CursorCircle open={open}>
        <CursorDragArrow open={arrowType === 'drag'} />
        <CursorDragArrow open={arrowType === 'drag'} />
        <CursorArrow open={arrowType === 'link'} />
      </CursorCircle>

      <CursorTextMask>
        <CursorText open={open}>{store.cursorLabel}</CursorText>
      </CursorTextMask>
    </CursorWrapper>
  )
}

Cursor.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
}

export default Cursor
