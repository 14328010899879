import styled, { css } from 'styled-components'
import { zIndex } from '../../styles/vars/layout.style'
import { mq } from '../../styles/vars/media-queries.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { setVh } from '../../styles/utils/conversion.style'
import mobileNavBackgroundNoiseImage from './images/mobileNavBackgroundNoise.jpg'
import mobileNavBackgroundImage from './images/mobileNavBackground.jpg'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'
import { transparentize } from 'polished'

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.header};

  ${mq.desk} {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`

export const HeaderMobileGradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: -3rem;
  left: 0;
  pointer-events: none;
  background: linear-gradient(
    to bottom,
    ${colors.dark} 50%,
    ${transparentize(1, colors.dark)}
  );
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.4s ease ${props => (props.show ? '0s' : '0.5s')};
`

export const HeaderMain = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  ${mq.tabletL} {
    min-height: 12rem;
  }
`

export const HeaderLogo = styled.h1`
  display: flex;
  width: 14.5rem;

  ${mq.tabletL} {
    width: 11.6rem;
  }

  svg {
    width: 100%;
    height: auto;
  }
`

export const MobileNav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  ${setVh('height', 100)}
  z-index: ${zIndex.mobileNav};
  overflow-y: scroll;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? undefined : 'none')};
  background-color: ${colors.dark};
  transition: opacity 0.2s ${easings.inOut.default};

  ${mq.tabletL} {
    display: none;
  }
`

export const MobileNavBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  min-width: 78.2rem;
  min-height: 107.3rem;
  transform: ${({ show }) =>
    show ? 'translate(-50%, 0)' : 'translate(-50%, 2em)'};
  background-image: url(${mobileNavBackgroundImage});
  background-image: url(${mobileNavBackgroundNoiseImage});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: transform 0.4s ${easings.inOut.default} 0.1s,
    opacity 0.2s ${easings.inOut.default} 0.1s;
`

export const MobileNavMain = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 9.5rem 2.5rem 4.1rem;
  overflow: hidden;
`

export const MobileMenu = styled.ul`
  padding: 0;
  list-style: none;
`

export const MobileMenuItem = styled.li`
  ${PageTransitionLinkMain} {
    display: block;
  }
`

export const MobileMenuButton = styled.button`
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 5.4rem;
  line-height: ${56 / 54};
  letter-spacing: 0.01em;
  text-transform: uppercase;

  text-align: left;

  ${MobileMenuItem}:first-child &,
  ${MobileMenuItem}:last-child & {
    text-align: right;
    max-width: 34rem;
  }
`

export const MobileMenuContact = styled.a`
  display: block;
  font-size: 2.8rem;
  line-height: ${38 / 28};
  letter-spacing: 0.01em;
  border-bottom: none;
`

export const MobileMenuLink = styled.a`
  display: inline-flex;
  font-size: 1.8rem;
  line-height: ${28 / 18};
  letter-spacing: 0.01em;
  border-bottom: none;
`

export const MobileMenuFooter = styled.p`
  margin-top: auto;
  padding-top: 1em;
  font-size: 1.2rem;
  line-height: ${21 / 12};
  letter-spacing: 0.01em;
`

export const HeaderNav = styled.nav`
  display: none;

  ${mq.tabletL} {
    position: relative;
    display: block;
    transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(-100%)')};
    transition: transform 0.5s ${easings.inOut.default};
    transition-delay: ${({ show }) => (show ? '0.3s' : '0.7s')};
  }
`

export const HeaderMenu = styled.ul`
  display: none;

  ${mq.tabletL} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 59.2rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    list-style: none;
    text-align: right;
  }
`

const headerLinkStyles = css`
  display: inline-flex;
  font-size: 1.8rem;
  line-height: ${21 / 18};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
`

const headerButtonStyles = css`
  padding: 0.9rem 1.5rem;
  border: 0.1rem solid ${colors.light};
  border-radius: 0.5rem;
`

export const HeaderMenuItem = styled.li`
  ${PageTransitionLinkMain} {
    ${headerLinkStyles}
  }

  :last-child ${PageTransitionLinkMain} {
    ${headerButtonStyles}
  }
`

export const HeaderMenuButton = styled.button`
  position: relative;
  ${headerLinkStyles}

  :after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 0.1rem;
    background-color: ${colors.light};
    transform-origin: 0% 50%;
    transform: ${({ active }) => (active ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 0.2s ${easings.inOut.default};
  }

  ${HeaderMenuItem}:last-child & {
    ${headerButtonStyles}
  }
`

export const HeaderBack = styled.div`
  padding-top: 4.9rem;
  padding-bottom: 4.9rem;
  ${headerLinkStyles}
  text-align: right;
`
