import React, { useState, useEffect, useContext, useCallback } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { isDesktop } from 'react-device-detect'

// Components
import Scroll from '../Scroll'
import { ContextDispatch, ContextState } from '../../context/Context'
import TransitionMask from '../PageTransitionMask'
import Gridlines from '../Gridlines'
import Header from '../Header'
// import CookieBar from '../CookieBar'

// Fonts
import NeueMontrealWOFF from '../../fonts/NeueMontreal/NeueMontreal-Regular.woff'
import NeueMontrealWOFF2 from '../../fonts/NeueMontreal/NeueMontreal-Regular.woff2'

// Styles
import { GlobalStyles } from '../../styles/GlobalStyles.style'
import { animation } from '../../styles/vars/animation.style'
import { font } from '../../styles/vars/font.style'
import Cursor from '../Cursor'
import ScrollContainer from '../ScrollContainer'
import WebGL from '../WebGL'
import { breakpoints } from '../../styles/vars/breakpoints.style'

const Layout = ({ children, location }) => {
  const [keyMode, setKeyMode] = useState(false)
  const [vw, setVw] = useState(null)
  const store = useContext(ContextState)
  const storeDispatch = useContext(ContextDispatch)
  const showGridlines = false

  const setMouseMode = useCallback(() => {
    if (!keyMode) return
    setKeyMode(false)
    document.body.classList.remove('keyboard-in-use')
  }, [keyMode])

  const setKeyboardMode = useCallback(() => {
    if (keyMode) return
    setKeyMode(true)
    document.body.classList.add('keyboard-in-use')
  }, [keyMode])

  const handleBrowserNavigationInteraction = useCallback(
    e => {
      const $webgl = document.getElementById('webgl-wrapper')
      gsap.to($webgl, {
        opacity: 1,
        duration: 0.1,
        ease: 'power2.in',
      })

      storeDispatch({ type: 'SHOW_TRANSITION_MASK' })
      storeDispatch({ type: 'SET_PREVIOUS_PAGE', url: store.pathname })
      storeDispatch({
        type: 'UPDATE_PATHNAME',
        payload: window.location.pathname,
      })
      storeDispatch({ type: 'UPDATE_POP_STATE' })

      setTimeout(() => {
        storeDispatch({ type: 'HIDE_TRANSITION_MASK' })
      }, animation.pageExitDuration * 1000)
    },
    [storeDispatch, store.pathname]
  )

  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    setVw(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('popstate', handleBrowserNavigationInteraction)

    return () => {
      window.removeEventListener('popstate', handleBrowserNavigationInteraction)
    }
  }, [handleBrowserNavigationInteraction])

  useEffect(() => {
    setViewportHeight()

    window.addEventListener('resize', setViewportHeight)
    document.body.addEventListener('mousedown', setMouseMode)
    document.body.addEventListener('keydown', setKeyboardMode)

    return () => {
      window.removeEventListener('resize', setViewportHeight)
      document.body.removeEventListener('mousedown', setMouseMode)
      document.body.removeEventListener('keydown', setKeyboardMode)
    }
  }, [setMouseMode, setKeyboardMode])

  useEffect(() => {
    setTimeout(() => {
      storeDispatch({ type: 'HIDE_TRANSITION_MASK' })
    }, animation.siteLoadDelay * 1000)
  }, [storeDispatch])

  return (
    <>
      <GlobalStyles />

      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <style>{`
          @font-face {
            font-family: 'Neue Montreal';
            src: url(${NeueMontrealWOFF}) format('woff'),
              url(${NeueMontrealWOFF2}) format('woff2');
            font-weight: ${font.primary.weight.regular};
          }
        `}</style>
      </Helmet>

      {showGridlines && <Gridlines show dark={false} />}

      <Scroll pathname={location.pathname} desktop={vw > breakpoints.desk} />

      <TransitionMask />

      <WebGL
        pathname={location.pathname}
        showGUI={false}
        desktop={vw > breakpoints.desk}
      />

      <ScrollContainer>
        <Header pathname={location.pathname} />

        <main>{children}</main>

        {/* <CookieBar /> */}
      </ScrollContainer>

      {isDesktop && <Cursor />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
