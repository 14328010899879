export const zIndex = {
  header: 20,
  headerLogo: 21,
  mobileNav: 22,
  mobileToggle: 23,
  cookieBar: 24,
  cursor: 30,
  datGUI: 35,
  pageTransitionMask: 40,
}

// Padding hierarchy 6,12,18,24,30,48,60,72,120,180,240
