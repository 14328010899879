import { createGlobalStyle } from 'styled-components'
import { reset } from './partials/reset.style'
import { typography } from './partials/typography.style'
import { locomotiveScrollStyles } from './partials/locomotiveScroll.style'
import { gatsbyTransitionLinkStyles } from './partials/gatsbyTransitionLink.style'
import { colors } from './vars/colors.style'
import { zIndex } from './vars/layout.style'

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${typography}
  ${locomotiveScrollStyles}
  ${gatsbyTransitionLinkStyles}

  * {
    cursor: none;
    -webkit-marquee-increment: 0vw;
  }

  body {
    background-color: ${colors.dark};

    &.disable-scroll {
      overflow: hidden;
    }
  }

  .dg.ac {
    z-index: ${zIndex.datGUI} !important;
  }
`
