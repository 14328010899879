import React, { useEffect, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { WebGLCanvas, WebGLOverlay } from './index.style'
import { ContextDispatch } from '../../context/Context'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import gsap from 'gsap'

const dat = typeof window !== 'undefined' ? require('dat.gui') : null

const WebGL = ({ pathname, showGUI }) => {
  const dispatch = useContext(ContextDispatch)
  const wrapperRef = useRef()

  useEffect(() => {
    const desktop = window.innerWidth > breakpoints.desk
    const defaultCameraZoom = desktop ? 0.4 : 0
    var DOM = {
      transitionMaskBlock: document.getElementById('transitionMaskBlock'),
    }
    var webglApi = window.webglApi
    var downTime = 0
    var downPixelXY = { x: 0, y: 0 }
    var mouseXY = { x: 0, y: 0 }
    var time = +new Date() / 1e3
    var myDemoConfig = {
      useAutoPlay: !1,
      orbitControls: !1,
      cameraLookStrength: 0.2,
      cameraLookEaseDamp: 0.075,
    }

    if (!webglApi) return
    webglApi.settings.DPR = 1
    webglApi.settings.FXAA_QUALITY = 10

    webglApi.properties.canvas = document.getElementById('webgl-canvas')
    webglApi.properties.frame = 0
    webglApi.properties.bloomAmount = 3.455
    webglApi.properties.bloomRadius = 0.543
    webglApi.properties.bloomThreshold = 0.422
    webglApi.properties.bloomSmoothWidth = 0.9
    webglApi.properties.bloomHaloWidth = 0.75
    webglApi.properties.bloomHaloRGBShift = 0.01175
    webglApi.properties.bloomHaloOpacity = 0.4
    webglApi.properties.bgColorHex = '#050505'
    webglApi.properties.vignetteFrom = 0
    webglApi.properties.vignetteTo = 1.4
    webglApi.properties.vignetteColorHex = '#050505'
    webglApi.properties.saturation = 1
    webglApi.properties.contrast = 0
    webglApi.properties.brightness = 1
    webglApi.properties.tintColorHex = '#383838'
    webglApi.properties.tintOpacity = 0.1
    webglApi.properties.refractionIndex = 1.2
    webglApi.properties.dispersion = 0.15
    webglApi.properties.diffuseStrength = 0.1
    webglApi.properties.specularStrength = 2.1
    webglApi.properties.noiseType = 2
    webglApi.properties.noiseStrength = desktop ? 0.5 : 0.25

    webglApi.properties.cameraShakePositionStrength = 0.3
    webglApi.properties.cameraShakePositionSpeed = 0.3
    webglApi.properties.cameraShakeRotationStrength = 0.003
    webglApi.properties.cameraShakeRotationSpeed = 0.3
    webglApi.properties.cameraLookStrength = 0.26
    webglApi.properties.cameraLookEaseDamp = 0.075
    webglApi.properties.reflectOffsetX = 0.26
    webglApi.properties.dustOpacity = 0.5
    webglApi.properties.cameraOffsetX = 0
    webglApi.properties.cameraOffsetY = 0
    webglApi.properties.cameraZoom = defaultCameraZoom

    function onDown(e) {
      downTime = +new Date()
      downPixelXY.x = e.clientX
      downPixelXY.y = e.clientY
      onMove(e)
    }

    function onMove(e) {
      mouseXY = getInputXY(e)
    }

    function onUp(e) {
      var o = e.clientX - downPixelXY.x
      var i = e.clientY - downPixelXY.y

      if (Math.sqrt(o * o + i * i) < 40 && +new Date() - downTime < 300) {
        mouseXY = getInputXY(e)
      }
    }

    function getInputXY(e) {
      return {
        x: (e.clientX / window.innerWidth) * 2 - 1,
        y: 1 - (e.clientY / window.innerHeight) * 2,
      }
    }

    function preInit() {
      webglApi.preInit(e => {
        if (e === 100) {
          init()
          loop()
        }
      })
    }

    function init() {
      webglApi.init()

      gsap.to(wrapperRef.current, {
        opacity: 0,
        duration: 0.25,
        ease: 'power2.out',
      })

      if (!webglApi.properties.isMobile) {
        document.addEventListener('mousedown', onDown)
        document.addEventListener('mousemove', onMove)
        document.addEventListener('mouseup', onUp)
      }

      if (showGUI) {
        initGUI()
      }
    }

    function initGUI() {
      var controls = new dat.GUI({ width: 400 })
      controls.close()

      const camera = controls.addFolder('Camera')
      camera.add(webglApi.properties, 'frame', 0, 1200, 1).listen()
      // camera
      //   .add(webglApi.properties, 'cameraShakePositionStrength', 0, 1, 1e-5)
      //   .listen()
      // camera
      //   .add(webglApi.properties, 'cameraShakePositionSpeed', 0, 1, 1e-5)
      //   .listen()
      // camera
      //   .add(webglApi.properties, 'cameraShakeRotationStrength', 0, 0.03, 1e-5)
      //   .listen()
      // camera
      //   .add(webglApi.properties, 'cameraShakeRotationSpeed', 0, 1, 1e-5)
      //   .listen()
      // camera.add(myDemoConfig, 'cameraLookStrength', 0, 0.6, 1e-5).listen()
      // camera.add(myDemoConfig, 'cameraLookEaseDamp', 0.01, 0.5, 1e-5).listen()
      camera.add(webglApi.properties, 'cameraZoom', -1, 5, 0.1).listen()
      camera.add(webglApi.properties, 'cameraOffsetX', -1, 1.3, 0.01).listen()
      camera.add(webglApi.properties, 'cameraOffsetY', -1.3, 0.8, 0.01).listen()

      const bloom = controls.addFolder('Bloom')
      bloom.add(webglApi.properties, 'bloomAmount', 0, 30, 1e-5).listen()
      bloom.add(webglApi.properties, 'bloomRadius', -4, 4, 1e-5).listen()
      bloom.add(webglApi.properties, 'bloomThreshold', 0, 1, 1e-5).listen()
      bloom.add(webglApi.properties, 'bloomSmoothWidth', 0, 5, 1e-5).listen()
      bloom.add(webglApi.properties, 'bloomHaloWidth', 0, 5, 1e-5).listen()
      bloom.add(webglApi.properties, 'bloomHaloRGBShift', 0, 0.3, 1e-5).listen()
      bloom.add(webglApi.properties, 'bloomHaloOpacity', 0, 8, 1e-5).listen()

      const color = controls.addFolder('Color')
      // color.addColor(webglApi.properties, 'bgColorHex')
      color.add(webglApi.properties, 'vignetteFrom', 0, 6, 1e-5).listen()
      color.add(webglApi.properties, 'vignetteTo', 0, 6, 1e-5).listen()
      // color.addColor(webglApi.properties, 'vignetteColorHex')
      // color.add(webglApi.properties, 'saturation', 0, 3, 1e-5).listen()
      // color.add(webglApi.properties, 'contrast', 0, 3, 1e-5).listen()
      // color.add(webglApi.properties, 'brightness', 0, 2, 1e-5)
      // color.addColor(webglApi.properties, 'tintColorHex').listen()
      // color.add(webglApi.properties, 'tintOpacity', 0, 1, 1e-5).listen()

      const material = controls.addFolder('Material')
      material
        .add(webglApi.properties, 'refractionIndex', 0.5, 5, 1e-5)
        .listen()
      material.add(webglApi.properties, 'dispersion', 0, 0.3, 1e-5).listen()
      material.add(webglApi.properties, 'diffuseStrength', 0, 10, 1e-5).listen()
      material
        .add(webglApi.properties, 'specularStrength', 0, 10, 1e-5)
        .listen()
      material.add(webglApi.properties, 'noiseType', [0, 1, 2])
      material.add(webglApi.properties, 'noiseStrength', 0, 4, 1e-5).listen()
      material.add(webglApi.properties, 'reflectOffsetX', 0, 0.5, 1e-5).listen()
      material.add(webglApi.properties, 'dustOpacity', 0, 1, 0.01).listen()
    }

    function onResize() {
      webglApi.resize(
        DOM.transitionMaskBlock.offsetWidth,
        DOM.transitionMaskBlock.offsetHeight
      )
    }

    function loop() {
      requestAnimationFrame(loop)
      render()
    }

    function render() {
      var e = +new Date() / 1e3
      var o = e - time
      var mouseTargetX = 0
      var mouseTargetY = 0

      webglApi.render(o)
      time = e

      if (window.followMouse) {
        mouseTargetX = mouseXY.x
        mouseTargetY = mouseXY.y
      }

      webglApi.properties.cameraLookX +=
        (mouseTargetY * myDemoConfig.cameraLookStrength -
          webglApi.properties.cameraLookX) *
        myDemoConfig.cameraLookEaseDamp

      webglApi.properties.cameraLookY +=
        (-mouseTargetX * myDemoConfig.cameraLookStrength -
          webglApi.properties.cameraLookY) *
        myDemoConfig.cameraLookEaseDamp
    }

    if (webglApi.isSupported(webglApi.properties.canvas)) {
      preInit()
      window.addEventListener('resize', onResize)
      onResize()
    }

    return () => {
      if (!webglApi.properties.isMobile) {
        document.removeEventListener('mousedown', onDown)
        document.removeEventListener('mousemove', onMove)
        document.removeEventListener('mouseup', onUp)
      }

      if (webglApi.isSupported(webglApi.properties.canvas)) {
        window.removeEventListener('resize', onResize)
      }
    }
  }, [dispatch, showGUI])

  useEffect(() => {
    const desktop = window.innerWidth > breakpoints.desk
    window.webglApi.properties.cameraZoom = desktop ? 0.4 : 0

    if (pathname !== '/') {
      window.webglApi.properties.frame = 48
      window.webglApi.properties.vignetteFrom = 0.87122
      window.webglApi.properties.vignetteTo = 0.44022
      window.webglApi.properties.bloomHaloOpacity = 0.15
    } else {
      window.webglApi.properties.frame = 0
      window.webglApi.properties.cameraOffsetY = 0
      window.webglApi.properties.vignetteFrom = 0
      window.webglApi.properties.vignetteTo = 1.4
      window.webglApi.properties.bloomHaloOpacity = 0.4
    }
  }, [pathname])

  return (
    <>
      <WebGLCanvas id="webgl-canvas" />
      <WebGLOverlay id="webgl-wrapper" ref={wrapperRef} />
    </>
  )
}

WebGL.propTypes = {
  pathname: PropTypes.string,
  showGUI: PropTypes.bool,
}

WebGL.defaultProps = {
  showGUI: false,
}

export default WebGL
