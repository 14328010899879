import React, { useReducer, createContext } from 'react'

export const ContextState = createContext()
export const ContextDispatch = createContext()

const initialState = {
  showTransitionMask: true,
  mobileNavIsOpen: false,
  pathname: typeof window === 'undefined' ? null : window.location.pathname,
  popstate: 0,
  showHeader: false,
  showHeaderGradient: false,
  servicesInView: -1,
  activeBlockIndex: 0,
  cursor: true,
  cursorLight: true,
  cursorOpen: false,
  cursorLink: false,
  cursorArrowType: 'none',
  cursorLabel: 'View',
  previousPage: null,
  scrollToIsActive: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_SCROLL_TO': {
      return {
        ...state,
        scrollToIsActive: true,
      }
    }
    case 'END_SCROLL_TO': {
      return {
        ...state,
        scrollToIsActive: false,
      }
    }

    case 'SET_ACTIVE_BLOCK_INDEX': {
      return {
        ...state,
        activeBlockIndex: action.payload,
      }
    }

    case 'SET_SERVICES_IN_VIEW': {
      return {
        ...state,
        servicesInView: action.payload,
      }
    }

    case 'SHOW_HEADER': {
      return {
        ...state,
        showHeader: true,
      }
    }
    case 'HIDE_HEADER': {
      return {
        ...state,
        showHeader: false,
      }
    }

    case 'SHOW_HEADER_GRADIENT': {
      return {
        ...state,
        showHeaderGradient: true,
      }
    }
    case 'HIDE_HEADER_GRADIENT': {
      return {
        ...state,
        showHeaderGradient: false,
      }
    }

    case 'SHOW_CURSOR': {
      return {
        ...state,
        cursor: true,
      }
    }
    case 'HIDE_CURSOR': {
      return {
        ...state,
        cursor: false,
      }
    }

    case 'OPEN_CURSOR': {
      return {
        ...state,
        cursorOpen: true,
        cursorArrowType: 'none',
        cursorLabel: action.payload,
      }
    }
    case 'OPEN_CURSOR_ARROW': {
      return {
        ...state,
        cursorOpen: true,
        cursorArrowType: 'link',
        cursorLabel: action.payload,
      }
    }
    case 'OPEN_CURSOR_DRAG': {
      return {
        ...state,
        cursorOpen: true,
        cursorArrowType: 'drag',
        cursorLabel: action.payload,
      }
    }
    case 'OPEN_CURSOR_LINK': {
      return {
        ...state,
        cursorLink: true,
      }
    }
    case 'CLOSE_CURSOR_LINK': {
      return {
        ...state,
        cursorLink: false,
      }
    }
    case 'CLOSE_CURSOR': {
      return {
        ...state,
        cursorOpen: false,
        cursorArrowType: 'none',
      }
    }

    case 'SET_CURSOR_LIGHT': {
      return {
        ...state,
        cursorLight: true,
      }
    }
    case 'SET_CURSOR_DARK': {
      return {
        ...state,
        cursorLight: false,
      }
    }

    case 'UPDATE_PATHNAME': {
      return {
        ...state,
        pathname: action.payload,
      }
    }

    case 'UPDATE_POP_STATE': {
      return {
        ...state,
        popstate: state.popstate + 1,
      }
    }

    case 'SHOW_TRANSITION_MASK': {
      return {
        ...state,
        showTransitionMask: true,
      }
    }
    case 'HIDE_TRANSITION_MASK': {
      return {
        ...state,
        showTransitionMask: false,
      }
    }

    case 'SHOW_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: true,
      }
    }
    case 'HIDE_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: false,
      }
    }
    case 'TOGGLE_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: !state.mobileNavIsOpen,
      }
    }

    case 'SET_PREVIOUS_PAGE': {
      return {
        ...state,
        previousPage: action.url,
      }
    }

    default:
      throw new Error('Bad action type')
  }
}

const Context = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ContextState.Provider value={state}>
      <ContextDispatch.Provider value={dispatch}>
        {props.children}
      </ContextDispatch.Provider>
    </ContextState.Provider>
  )
}

export default Context
