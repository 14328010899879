import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Logo from '../_svgs/Logo'
import Container from '../_layouts/Container'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import PageTransitionLink from '../PageTransitionLink'
import Spacer from '../Spacer'
import Tetris from '../Tetris'
import IconAccordionToggle from '../_svgs/IconAccordionToggle'
import {
  HeaderBack,
  HeaderContainer,
  HeaderMobileGradient,
  HeaderLogo,
  HeaderMain,
  HeaderMenu,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderNav,
  MobileMenu,
  MobileMenuButton,
  MobileMenuContact,
  MobileMenuFooter,
  MobileMenuItem,
  MobileMenuLink,
  MobileNav,
  MobileNavBackground,
  MobileNavMain,
} from './index.style'
import MenuToggle from '../MenuToggle'
import { ContextDispatch, ContextState } from '../../context/Context'
import { scrollTo } from '../../utils/utils'
import { colors } from '../../styles/vars/colors.style'
import CharacterMask from '../CharacterMask'

const Header = ({ pathname }) => {
  const store = useContext(ContextState)
  const dispatch = useContext(ContextDispatch)
  const { mobileNavIsOpen, showHeader, showHeaderGradient } = store
  const {
    allContentfulGlobals: {
      nodes: [
        {
          contactEmail,
          instagramUrl,
          twitterUrl,
          linkedInUrl,
          instagramVisibility,
          twitterVisibility,
          linkedInVisibility,
        },
      ],
    },
    site: {
      siteMetadata: { blocks, routes },
    },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      allContentfulGlobals {
        nodes {
          contactEmail
          instagramUrl
          twitterUrl
          linkedInUrl
          instagramVisibility
          twitterVisibility
          linkedInVisibility
        }
      }
      site {
        siteMetadata {
          routes {
            home
            work
            privacyPolicy
            termsOfUse
          }
          blocks {
            label
            id
          }
        }
      }
    }
  `)

  const mobileScrollTo = id => {
    dispatch({ type: 'HIDE_MOBILE_NAV' })
    scrollTo(id, dispatch)
  }

  return (
    <HeaderContainer>
      <HeaderMobileGradient show={showHeader && showHeaderGradient} />

      <Container>
        <HeaderMain>
          <PageTransitionLink
            to={routes.home}
            onMouseEnter={() => dispatch({ type: 'OPEN_CURSOR_LINK' })}
            onMouseLeave={() => dispatch({ type: 'CLOSE_CURSOR_LINK' })}
          >
            <HeaderLogo>
              <Logo show={showHeader} responsive />
            </HeaderLogo>
          </PageTransitionLink>

          <MenuToggle />

          <HeaderNav show={showHeader}>
            {pathname === routes.home && (
              <HeaderMenu>
                {React.Children.toArray(
                  blocks.map((block, blockIndex) => (
                    <HeaderMenuItem>
                      <HeaderMenuButton
                        active={blockIndex + 1 === store.activeBlockIndex}
                        onClick={() => scrollTo(block.id, dispatch)}
                        onMouseEnter={() =>
                          dispatch({ type: 'OPEN_CURSOR_LINK' })
                        }
                        onMouseLeave={() =>
                          dispatch({ type: 'CLOSE_CURSOR_LINK' })
                        }
                      >
                        <CharacterMask>{block.label}</CharacterMask>
                      </HeaderMenuButton>
                    </HeaderMenuItem>
                  ))
                )}
                <HeaderMenuItem>
                  <HeaderMenuButton
                    as="a"
                    href={`mailto:${contactEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseEnter={() => dispatch({ type: 'OPEN_CURSOR_LINK' })}
                    onMouseLeave={() => dispatch({ type: 'CLOSE_CURSOR_LINK' })}
                  >
                    <CharacterMask>Contact</CharacterMask>
                  </HeaderMenuButton>
                </HeaderMenuItem>
              </HeaderMenu>
            )}

            {pathname.includes(routes.work) && (
              <HeaderBack>
                <PageTransitionLink
                  to={routes.home}
                  onClick={() =>
                    dispatch({ type: 'SET_PREVIOUS_PAGE', url: '/work/' })
                  }
                  onMouseEnter={() => dispatch({ type: 'OPEN_CURSOR_LINK' })}
                  onMouseLeave={() => dispatch({ type: 'CLOSE_CURSOR_LINK' })}
                >
                  <CharacterMask>{`Back to ${blocks[1].label}`}</CharacterMask>
                </PageTransitionLink>
              </HeaderBack>
            )}

            {(pathname.includes(routes.privacyPolicy) ||
              pathname.includes(routes.termsOfUse)) && (
              <HeaderBack>
                <PageTransitionLink
                  to={routes.home}
                  onMouseEnter={() => dispatch({ type: 'OPEN_CURSOR_LINK' })}
                  onMouseLeave={() => dispatch({ type: 'CLOSE_CURSOR_LINK' })}
                >
                  <IconAccordionToggle
                    fill={colors.light}
                    width={30}
                    height={30}
                    rotate
                  />
                </PageTransitionLink>
              </HeaderBack>
            )}
          </HeaderNav>
        </HeaderMain>
      </Container>

      <MobileNav show={mobileNavIsOpen} data-inview={mobileNavIsOpen}>
        <MobileNavMain>
          <MobileNavBackground show={mobileNavIsOpen} />
          <MobileMenu>
            {React.Children.toArray(
              blocks.map((block, blockIndex) => (
                <MobileMenuItem>
                  <AnimateSlideIn
                    delay={0.3 + blockIndex * 0.05}
                    duration={0.5}
                    direction={blockIndex === 0 ? 'right' : 'bottom'}
                  >
                    {pathname === routes.home ? (
                      <MobileMenuButton
                        onClick={() => mobileScrollTo(block.id)}
                      >
                        {block.label}
                      </MobileMenuButton>
                    ) : (
                      <PageTransitionLink to={`${routes.home}?${block.id}`}>
                        <MobileMenuButton as="span">
                          {block.label}
                        </MobileMenuButton>
                      </PageTransitionLink>
                    )}
                  </AnimateSlideIn>
                </MobileMenuItem>
              ))
            )}
            <MobileMenuItem>
              <AnimateSlideIn
                delay={0.3 + blocks.length * 0.05}
                duration={0.5}
                direction="right"
              >
                <MobileMenuButton
                  as="a"
                  href={`mailto:${contactEmail}`}
                  target="_blank"
                >
                  Contact
                </MobileMenuButton>
              </AnimateSlideIn>
            </MobileMenuItem>
          </MobileMenu>

          <Spacer size={30} />

          <AnimateSlideIn delay={0.5}>
            <MobileMenuContact href={`mailto:${contactEmail}`} target="_blank">
              {contactEmail}
            </MobileMenuContact>
          </AnimateSlideIn>

          <Spacer size={60} />

          <AnimateSlideIn delay={0.5}>
            <Tetris />
          </AnimateSlideIn>

          <Spacer size={60} />

          {instagramVisibility && (
            <AnimateSlideIn delay={0.5}>
              <MobileMenuLink href={instagramUrl}>Instagram</MobileMenuLink>
            </AnimateSlideIn>
          )}

          {linkedInVisibility && (
            <AnimateSlideIn delay={0.5}>
              <MobileMenuLink href={linkedInUrl}>LinkedIn</MobileMenuLink>
            </AnimateSlideIn>
          )}

          {twitterVisibility && (
            <AnimateSlideIn delay={0.5}>
              <MobileMenuLink href={twitterUrl}>Twitter</MobileMenuLink>
            </AnimateSlideIn>
          )}

          <AnimateSlideIn delay={0.5}>
            <MobileMenuFooter>
              &copy;{new Date().getFullYear()} Vivid Group Holdings, Inc. All
              rights reserved.
            </MobileMenuFooter>
          </AnimateSlideIn>
        </MobileNavMain>
      </MobileNav>
    </HeaderContainer>
  )
}

export default Header
