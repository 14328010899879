import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { zIndex } from '../../styles/vars/layout.style'
import { mq } from '../../styles/vars/media-queries.style'

export const MenuToggleWrapper = styled.div`
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
  z-index: ${zIndex.mobileToggle};
`

export const MenuToggleMain = styled.button`
  position: relative;
  width: 4.4rem;
  height: 4.4rem;
  background-color: transparent;
  border: none;

  ${mq.tabletL} {
    display: none;
  }
`

export const MenuToggleBar = styled.div`
  position: absolute;
  top: calc(50% - 0.1rem);
  left: calc(50% - 1.9rem);
  width: 3.8rem;
  height: 0.2rem;
  background-color: ${colors.light};

  :first-child {
    margin-top: -0.5rem;
  }

  :last-child {
    margin-top: 0.5rem;
  }
`
