import React from 'react'

const IconAccordionToggle = ({
  width = 20,
  height = 20,
  fill = 'currentColor',
  responsive = false,
  open = false,
  rotate = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  const transform = rotate ? 'rotate(45deg)' : 'rotate(0deg)'

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      style={{ transform }}
      {...svgAttributes}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10.5001H0V9.50012H20V10.5001Z"
        fill={fill}
        opacity={open ? 0 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 20L9.5 -4.37114e-08L10.5 0L10.5 20L9.5 20Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconAccordionToggle
