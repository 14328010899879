import styled from 'styled-components'
import { setVh } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { zIndex } from '../../styles/vars/layout.style'

export const TransitionMaskWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  pointer-events: none;
  z-index: ${zIndex.pageTransitionMask};
`

export const TransitionMaskBlock = styled.div`
  position: relative;
  transform-origin: 50% 100%;
  width: 100%;
  ${setVh('height', 100)}
  z-index: 1;
  background-color: ${colors.dark};
`
