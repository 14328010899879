import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { colors } from '../../../styles/vars/colors.style'
import { LogoSVG, PlusPath } from './index.style'

const Logo = ({
  width = 145,
  height = 26,
  fill = colors.light,
  responsive = false,
  show,
}) => {
  const logoRef = useRef()
  const plusPathRef = useRef()
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  useEffect(() => {
    const tl = gsap.timeline()

    tl.to(logoRef.current, {
      yPercent: show ? 0 : -100,
      duration: 0.6,
      delay: show ? 0 : 0.4,
      ease: 'inOutDefault',
    }).to(
      plusPathRef.current,
      {
        rotate: show ? 0 : -180,
        transformOrigin: '50% 50%',
        duration: 1,
        ease: show ? 'power3.inOut' : 'power4.out',
      },
      '<'
    )

    return () => {
      tl.kill()
    }
  }, [show])

  return (
    <LogoSVG
      ref={logoRef}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 145 26"
      aria-labelledby="logoTitle"
      focusable="false"
      fill="none"
      role="img"
      {...svgAttributes}
    >
      <title id="logoTitle">Vivid+Co</title>

      <path
        d="M0 1.11952H5.02063L11.4636 21.5477L18.0573 1.10547H22.9932L15.1231 24.7283H7.7994L0 1.11952Z"
        fill={fill}
      />
      <path
        d="M25.1784 6.8531H29.5538V24.7283H25.1784V6.8531ZM29.5538 2.16882C29.5585 3.37268 28.5836 4.3517 27.3732 4.35638C26.1628 4.36106 25.1784 3.39142 25.1737 2.18756C25.169 0.983699 26.1439 0.00468428 27.3544 0C27.3591 0 27.3638 0 27.3685 0C28.5742 0 29.5538 0.97433 29.5538 2.16882C29.5538 2.17351 29.5538 2.17819 29.5538 2.17819V2.16882Z"
        fill={fill}
      />
      <path
        d="M31.7435 6.85303H36.425L40.9747 21.9317L45.5432 6.85303H50.2247L44.573 24.7282H37.4047L31.7435 6.85303Z"
        fill={fill}
      />
      <path d="M52.415 6.853h4.375v17.875h-4.375V6.853z" fill={fill} />
      <path
        d="M54.6 4.356c1.21 0 2.19-.975 2.19-2.178C56.79.975 55.81 0 54.6 0s-2.19.975-2.19 2.178c0 1.203.98 2.178 2.19 2.178z"
        fill={fill}
      />
      <path
        d="M67.8959 25.0001C66.3228 25.0048 64.7874 24.5644 63.4546 23.7353C62.0887 22.9109 60.9631 21.7492 60.1813 20.3626C59.3806 18.9714 58.9661 17.3928 58.9756 15.7908C58.9614 14.1607 59.3759 12.5586 60.1813 11.1393C60.949 9.75744 62.0746 8.59574 63.4357 7.78067C64.778 6.97029 66.3228 6.54871 67.8959 6.55808C68.932 6.54402 69.9635 6.75013 70.9149 7.16235C71.8003 7.56051 72.5963 8.12263 73.2697 8.8159V1.11963H77.7111V24.7284H73.3027V22.8078C72.6339 23.5011 71.8333 24.0492 70.9478 24.4286C69.9823 24.8221 68.9415 25.0188 67.8959 25.0001ZM68.3339 21.0559C69.2523 21.0606 70.1472 20.8077 70.929 20.3299C71.7249 19.8567 72.3843 19.1869 72.8459 18.3906C73.7925 16.7885 73.7925 14.7977 72.8459 13.1957C72.3843 12.39 71.7249 11.7202 70.929 11.2424C70.1472 10.7646 69.2523 10.5116 68.3339 10.5163C67.3967 10.5163 66.4782 10.7646 65.6729 11.2424C64.8816 11.7014 64.2317 12.3666 63.789 13.1676C63.3415 13.9686 63.106 14.8727 63.1108 15.7908C63.1013 17.6411 64.0668 19.3649 65.654 20.3299C66.4594 20.8264 67.3872 21.084 68.3339 21.0793V21.0559Z"
        fill={fill}
      />
      <PlusPath
        ref={plusPathRef}
        show={show}
        fill={fill}
        d="M88.544 16.8024L83.0995 18.2686L82.0728 14.4603L87.5078 13.0082L86.1326 7.89294L89.9569 6.85303L91.3369 11.9683L96.739 10.5302L97.7657 14.3385L92.3636 15.7813L93.7436 20.8965L89.9098 21.9177L88.544 16.8024Z"
      />
      <path
        d="M113.85 25.0003C111.777 25.0143 109.747 24.4569 107.981 23.3795C106.21 22.3068 104.75 20.7985 103.742 18.995C102.692 17.1588 102.146 15.0743 102.165 12.9617C102.146 8.71303 104.35 4.75481 107.981 2.52041C109.743 1.43834 111.777 0.87154 113.85 0.885593C116.106 0.857487 118.31 1.54608 120.142 2.85767C121.998 4.17864 123.453 5.98209 124.338 8.07128L120.132 9.3173C119.553 8.12749 118.682 7.10163 117.599 6.33341C116.497 5.56987 115.178 5.17171 113.831 5.2045C112.517 5.18576 111.222 5.54177 110.101 6.23036C108.994 6.91895 108.09 7.88859 107.487 9.04092C106.851 10.2495 106.526 11.5939 106.545 12.957C106.526 14.3107 106.851 15.6504 107.487 16.8496C108.09 18.002 108.994 18.9716 110.101 19.6602C111.222 20.3488 112.517 20.7048 113.831 20.686C115.145 20.7188 116.44 20.3535 117.542 19.6415C118.625 18.9107 119.478 17.8942 120.01 16.7091L124.282 17.8895C123.415 19.9647 121.974 21.7541 120.123 23.0422C118.291 24.3351 116.096 25.019 113.85 25.0003Z"
        fill={fill}
      />
      <path
        d="M135.745 25.0001C134.115 25.0095 132.509 24.5738 131.115 23.7354C129.721 22.9109 128.563 21.7445 127.757 20.3439C126.104 17.5006 126.104 13.9967 127.757 11.1534C128.567 9.76683 129.721 8.60982 131.115 7.79944C133.974 6.13652 137.516 6.13652 140.375 7.79944C141.769 8.61918 142.932 9.78089 143.747 11.1721C145.419 14.0155 145.419 17.5381 143.747 20.3814C142.932 21.7726 141.769 22.9343 140.375 23.7541C138.976 24.5785 137.375 25.0095 135.745 25.0001ZM135.745 20.9857C136.659 20.9951 137.558 20.7515 138.34 20.2784C139.122 19.8146 139.767 19.1541 140.224 18.3719C141.147 16.7511 141.147 14.765 140.224 13.1442C139.767 12.3666 139.122 11.7249 138.34 11.2705C137.554 10.8067 136.659 10.5678 135.745 10.5772C134.836 10.5725 133.946 10.8208 133.169 11.2892C132.387 11.7389 131.737 12.3853 131.285 13.1629C130.371 14.779 130.371 16.7558 131.285 18.3719C131.737 19.1588 132.387 19.8146 133.169 20.2784C133.946 20.7515 134.836 20.9951 135.745 20.9857Z"
        fill={fill}
      />
    </LogoSVG>
  )
}

export default Logo
