import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { mq } from '../../../styles/vars/media-queries.style'

export const ContainerMain = styled.div`
  width: 100%;
  margin: 0 auto;
  ${clamp('padding-left', 25, 70, breakpoints.mobile, breakpoints.tabletL)}
  ${clamp('padding-right', 25, 70, breakpoints.mobile, breakpoints.tabletL)}

  ${mq.desk} {
    padding-left: 0;
    padding-right: 0;
  }
`
